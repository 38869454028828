const supporters = [
  {
    first: 'Lloyd',
    last: 'Doggett',
    honorable: true,
  },
  {
    first: 'Kirk',
    last: 'Watson',
    honorable: true,
  },
  {
    first: 'Gonzalo',
    last: 'Barrientos',
    honorable: true,
  },
  {
    first: 'Gina',
    last: 'Hinojosa',
    honorable: true,
  },
  {
    first: 'Sheryl',
    last: 'Cole',
    honorable: true,
  },
  {
    first: 'Eddie',
    last: 'Rodriguez',
    honorable: true,
  },
  {
    first: 'Vicki',
    last: 'Goodwin',
    honorable: true,
  },
  {
    first: 'David',
    last: 'Escamilla',
    honorable: true,
  },
  {
    first: 'Sally',
    last: 'Hernandez',
    honorable: true,
  },
  {
    first: 'Margaret',
    last: 'Gomez',
    honorable: true,
  },
  {
    first: 'Brigid',
    last: 'Shea',
    honorable: true,
  },
  {
    first: 'Velva',
    last: 'Price',
    honorable: true,
  },
  {
    first: 'Leslie',
    last: 'Pool',
    honorable: true,
  },
  {
    first: 'Danny',
    last: 'Thomas',
    honorable: true,
  },
  {
    first: 'George',
    last: 'Morales',
    honorable: true,
  },
  {
    first: 'Stacy',
    last: 'Suits',
    honorable: true,
  },
  {
    first: 'Maria',
    last: 'Canchola',
    honorable: true,
  },
  {
    first: 'Dolores',
    last: 'Carter-Ortega',
    honorable: true,
  },
  {
    first: 'Cecelia',
    last: 'Burke',
    honorable: true,
  },
  {
    first: 'Dr. Guadalupe',
    last: 'Zamora',
    honorable: false,
  },
  {
    first: 'Julia',
    last: 'Spann',
    honorable: false,
  },
  {
    first: 'Lisa',
    last: 'Harris',
    honorable: false,
  },
  {
    first: 'Carl & Barbara',
    last: 'Daywood',
    honorable: false,
  },
  {
    first: 'Gloria',
    last: 'Aleman',
    honorable: false,
  },
  {
    first: 'Sylvia',
    last: 'Camarillo',
    honorable: false,
  },
  {
    first: 'Fred',
    last: 'Cantu',
    honorable: false,
  },
  {
    first: 'Bertha',
    last: 'DelaCruz',
    honorable: false,
  },
  {
    first: 'Connie',
    last: 'Eller',
    honorable: false,
  },
  {
    first: 'Kelley',
    last: 'Gall',
    honorable: false,
  },
  {
    first: 'Rocc',
    last: 'Larocca',
    honorable: false,
  },
  {
    first: 'Gina',
    last: 'Garza',
    honorable: false,
  },
  {
    first: 'Kirsha',
    last: 'Haverlah',
    honorable: false,
  },
  {
    first: 'Monica',
    last: 'Hernandez',
    honorable: false,
  },
  {
    first: 'Cinda',
    last: 'Kortan',
    honorable: false,
  },
  {
    first: 'Bobby & Silvia',
    last: 'Lopez',
    honorable: false,
  },
  {
    first: 'Ray M',
    last: 'Lopez',
    honorable: false,
  },
  {
    first: 'Lisa',
    last: 'Lucio',
    honorable: false,
  },
  {
    first: 'Raymond & Mary Alice',
    last: 'Lopez',
    honorable: false,
  },
  {
    first: 'Donna Beth',
    last: 'McCormick',
    honorable: false,
  },
  {
    first: 'Tonya',
    last: 'Nixon',
    honorable: false,
  },
  {
    first: 'Krissy',
    last: "O'Brien",
    honorable: false,
  },
  {
    first: 'Sandra',
    last: 'Tenorio',
    honorable: false,
  },
  {
    first: 'Leticia',
    last: 'Vallejo',
    honorable: false,
  },
  {
    first: 'Hermelinda',
    last: 'Zamarripa',
    honorable: false,
  },
  {
    first: 'Daniel',
    last: 'Landeros',
    honorable: false,
  },
  {
    first: 'Wendy',
    last: 'Kathloff',
    honorable: false,
  },
  {
    first: 'Gail',
    last: 'Rice',
    honorable: false,
  },
  {
    first: 'Chuy',
    last: 'Chacos',
    honorable: false,
  },
  {
    first: 'David',
    last: 'Chincanchan',
    honorable: false,
  },
  {
    first: 'Clint & Stephanie',
    last: 'Bordelon',
    honorable: false,
  },
  {
    first: 'Nick & Christina',
    last: 'Lopez',
    honorable: false,
  },
  {
    first: 'Larry & Jeanette',
    last: 'Ybarra',
    honorable: false,
  },
  {
    first: 'Alicia',
    last: 'Owens',
    honorable: false,
  },
  {
    first: 'Jack',
    last: 'Kirfman',
    honorable: false,
  },
  {
    first: 'Gloria',
    last: 'Dholakia',
    honorable: false,
  },
  {
    first: 'Tim',
    last: 'Mahoney',
    honorable: false,
  },
  {
    first: 'Tiffanie',
    last: 'Berry-Johnson',
    honorable: false,
  },
  {
    first: 'Ken',
    last: 'Craig',
    honorable: false,
  },
  {
    first: 'Rosie',
    last: 'Chavez',
    honorable: false,
  },
  {
    first: 'Abram',
    last: 'Gonzalez',
    honorable: false,
  },
  {
    first: 'Phillip',
    last: 'George',
    honorable: false,
  },
  {
    first: 'Michelle',
    last: 'Walter',
    honorable: false,
  },
  {
    first: 'Dennis',
    last: 'Walter',
    honorable: false,
  },
  {
    first: 'Craig',
    last: 'Moore',
    honorable: false,
  },
  {
    first: 'Royce',
    last: 'West',
    honorable: true,
  },
  {
    first: 'Jennifer',
    last: 'Lopez',
    honorable: false,
  },
];

export default supporters;
