import { Link } from 'gatsby';
import React from 'react';
import {
  FaBars,
  FaFacebookSquare,
  FaTwitterSquare,
  FaEnvelopeSquare,
  FaInstagram,
} from 'react-icons/fa';

class Header extends React.Component {
  toggleMenu() {
    document.querySelector('.header-links').classList.toggle('open');
  }

  render() {
    return (
      <header>
        <nav className="header-wrapper">
          <div className="header-toggle" onClick={this.toggleMenu}>
            <FaBars className="header-dropdown" focusable="false" />
          </div>
          <div className="header-links">
            <Link className="header-link" to="/" onClick={this.toggleMenu}>
              HOME
            </Link>
            <Link className="header-link" to="/about" onClick={this.toggleMenu}>
              ABOUT
            </Link>
            <Link
              className="header-link"
              to="/supporters"
              onClick={this.toggleMenu}
            >
              SUPPORTERS
            </Link>
            <div className="header-icons">
              <a
                className="header-facebook"
                href="https://www.facebook.com/constablecarlos"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Facebook"
              >
                <FaFacebookSquare
                  className="header-facebook-icon"
                  focusable="false"
                />
              </a>
              <a
                className="header-twitter"
                href="https://twitter.com/CarlosBLopezcn5"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Twitter"
              >
                <FaTwitterSquare
                  className="header-twitter-icon"
                  focusable="false"
                />
              </a>
              <a
                className="header-instagram"
                href="https://www.instagram.com/constablecarlos/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="Instagram"
              >
                <FaInstagram
                  className="header-instagram-icon"
                  focusable="false"
                />
              </a>
              <a
                className="header-email"
                href="mailto:carlosforconstable@gmail.com"
                aria-label="Email Carlos"
              >
                <FaEnvelopeSquare
                  className="header-email-icon"
                  focusable="false"
                />
              </a>
            </div>
          </div>

          <a
            className="header-donate"
            target="_blank"
            rel="noreferrer noopener"
            href="https://donateway.com/donate/n8xLzzqp/re-elect-constable-carlos-b-lopez"
          >
            DONATE
          </a>
        </nav>
      </header>
    );
  }
}

export default Header;
