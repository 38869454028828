// import { Link } from 'gatsby';
// import PropTypes from 'prop-types';
import React from 'react';
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaEnvelopeSquare,
  FaInstagram,
} from 'react-icons/fa';

const Footer = () => (
  <footer>
    <div className="footer-primary">
      <div className="footer-donate">
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://donateway.com/donate/n8xLzzqp/re-elect-constable-carlos-b-lopez"
        >
          DONATE
        </a>
      </div>
      <div className="footer-buttons">
        <div className="footer-facebook">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.facebook.com/constablecarlos"
            aria-label="Facebook"
          >
            <FaFacebookSquare focusable="false" />
          </a>
        </div>
        <div className="footer-twitter">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://twitter.com/CarlosBLopezcn5"
            aria-label="Twitter"
          >
            <FaTwitterSquare focusable="false" />
          </a>
        </div>
        <div className="footer-instagram">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.instagram.com/constablecarlos/"
            aria-label="Instagram"
          >
            <FaInstagram focusable="false" />
          </a>
        </div>
        <div className="footer-email">
          <a
            href="mailto:carlosforconstable@gmail.com"
            aria-label="Email Carlos"
          >
            <FaEnvelopeSquare focusable="false" />
          </a>
        </div>
      </div>
      <div className="footer-address">
        <p>Carlos Lopez Campaign</p>
        <p>P.O. Box 300115</p>
        <p>Austin, TX 78703</p>
      </div>
    </div>
    <div className="footer-secondary">
      <p className="footer-disclosure">Pol. Adv. Carlos Lopez Campaign</p>
      <p>
        A{' '}
        <a
          className="danbuda-link"
          target="_blank"
          rel="noreferrer noopener"
          href="https://danbuda.com"
        >
          Dan Buda
        </a>{' '}
        Joint
      </p>
    </div>
  </footer>
);

export default Footer;
