import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import data from '../data/supporters';

const Supporters = () => {
  const sortedData = data
    .sort((a, b) => {
      const nameA = a.last.toLowerCase();
      const nameB = b.last.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    .map(supporter => {
      if (supporter.honorable) {
        return (
          <li
            className="supporter honorable"
            key={`${supporter.first}${supporter.last}`}
          >
            The Honorable {supporter.first} {supporter.last}
          </li>
        );
      }
      return (
        <li className="supporter" key={`${supporter.first}${supporter.last}`}>
          {supporter.first} {supporter.last}
        </li>
      );
    });

  return (
    <Layout>
      <SEO title="Support for Carlos" />
      <div className="supporters-page">
        <h2>Supporters</h2>
        {/* <div className="supporters-img-wrapper">
          <img
            src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1568066588/carloslopez/cbl_judges_r6n0a3.jpg"
            alt="carlos and supporters"
          />
        </div> */}
        <div className="supporters-page-img-wrapper">
          <img
            src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1574099189/carloslopez/supporters_p4ih79.jpg"
            alt="carlos supporters"
            className="supporters-img"
          />
        </div>
        <p className="supporters-add-name">
          Contact <a href="mailto:carlosforconstable@gmail.com">Carlos</a> to
          add your support!
        </p>
        <ul>{sortedData}</ul>
      </div>
    </Layout>
  );
};

export default Supporters;
